import React,{useState, useEffect} from "react";
import { graphql} from "gatsby"
import { useLocation } from "@reach/router"
import { useQuery} from '@apollo/client';
import { Helmet } from "react-helmet";

import LocRatingMap from "gatsby-theme-starberry-lomondgroup/src/components/map/loc-rating-map-details"
import Layout from "gatsby-theme-starberry-lomondgroup/src/components/Layout"
import Customer from "gatsby-theme-starberry-lomondgroup/src/components/Customer/Customer";
import BranchBanner from "gatsby-theme-starberry-lomondgroup/src/components/BranchBanner/BranchBanner";
import Counter from "gatsby-theme-starberry-lomondgroup/src/components/Counter/Counter";
import FeaturesOffice from "gatsby-theme-starberry-lomondgroup/src/components/Features/FeaturesOffice";
import Journey from "gatsby-theme-starberry-lomondgroup/src/components/Journey/Journey";
import Reviews from "gatsby-theme-starberry-lomondgroup/src/components/Reviews/Reviews";
import Team from "gatsby-theme-starberry-lomondgroup/src/components/Team/TeamOffice"
import "gatsby-theme-starberry-lomondgroup/src/scss/branch-details.scss";
import ContactForm from "gatsby-theme-starberry-lomondgroup/src/components/ContactForm/BranchContactForm";
import { OfficeDetails } from "gatsby-theme-starberry-lomondgroup/src/common/queries/common_use_query"
import LocalExperience from "gatsby-theme-starberry-lomondgroup/src/components/LocalExperience/LocalExperienceOffice"
import PreLoader from "gatsby-theme-starberry-lomondgroup/src/modules/pre-loader"

// markup
const BranchDetails = (props) => {
    const location = useLocation();
    // const[officeDeta, setOfficeDet] = useState("");

    var office = props.data.glstrapi?.office;
    var GQLModules = props.data.glstrapi?.office?.Modules;
    var globalModule = props.data.glstrapi?.globalModule;

    // const { loading, error, data } = OfficeDetails(props.pageContext.slug);

    // useEffect(()=>{
    //     data?.offices?.length > 0 && setOfficeDet(data?.offices[0]);
    // },[data])

    let tab_list = [
        {
            "tab_name": "Property for Sale",
            "add_tab": "residential_sales"
        },
        {
            "tab_name": "Property to Rent",
            "add_tab": "residential_lettings"
        }
    ]

    if(process.env.GATSBY_SITE_NAME === "Stonehouse"){
        tab_list = [
            {
                "tab_name": "Property to Rent",
                "add_tab": "residential_lettings"
            }
        ]
    }

    // const { glstrapi: { office, globalModule } } = data;
    // //console.log('globalModule ==>', globalModule)

    // Get Map Details
    let mapItems = [];
    let mapItem = {};
    mapItem['lat']  = office.latitude      
    mapItem['lng']  = office.longitude
    mapItem['id']  = office.id

    const mapMarkerImageShowFlag = 0;
    mapItems.push( mapItem );
    // Get Map Details

    let h1_title = office?.seo_meta?.title ? office?.seo_meta?.title : office?.name ? office?.name : "";

    let meta_desc = office?.seo_meta?.description ? office?.seo_meta?.description : `Our ${h1_title} offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance.`;

    let meta_data = h1_title ? {title: `${h1_title}`, description: meta_desc} : "";

    var type = props?.pageContext?.type;

    //console.log("location ==>", location);

    var opening_hours = ""
    office?.opening_hours.length > 0 && office?.opening_hours.map((item) => {
        opening_hours += `${item.name}: ${item.value}, `
    })

    //console.log("opening_hours", opening_hours)
    var ldJson = `{
        "@context": "https://www.schema.org",
        "@type": "RealEstateAgent",
        "name": "${process.env.GATSBY_SITE_NAME} ${h1_title}",
        "url": "${process.env.GATSBY_SITE_URL}/",
        "logo": "${process.env.GATSBY_SITE_URL}/images/mail-logo.png",
        "image": "${office?.image?.url ? office?.image?.url : ''}",
        "description": "Our ${h1_title} offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance.",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "${office?.address}"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": "${office?.latitude ? office?.latitude : ''}",
            "longitude": "${office?.longitude ? office?.longitude : ''}"
        },
        "openingHours": "${opening_hours}",
        "telephone": "${office?.mobile ? office?.mobile : ''}",
        "contactPoint": 	{
            "@type": "ContactPoint",
            "contactType": "Sales",
            "telephone": "${office?.mobile ? office?.mobile : ''}"
        }
    }`


    return (
        // loading ? 
        //     <PreLoader />
        // :
        <div className={`search-header detail-page office_details ${type === "contact-form" ? "contact-field" : ""}`}>
            {office?.name &&
            <Layout seo_meta={meta_data} menu_slug={"office-details"} isJourneySec={type === "contact-form" ? false : true}>
                <Helmet>
                    <script  type="application/ld+json">{ldJson}</script>
                </Helmet>
                
                {type === "contact-form" ?
                    <ContactForm office_data={office} location={location}/>
                :
                <>
                    <BranchBanner office_data={office} location={location} h1_title={h1_title} />

                    {office?.video_section && <Customer Module={office.video_section} GQLPage={office} office_template={true} /> }

                    {(office?.show_statistics && office?.statistics_component?.length > 0) && <Counter Module={office} />}

                    <Team Module={{title:`${office?.name} Branch Team`}} category={"lettings"} team_data={office?.teams} />
                    
                    {GQLModules?.length > 0 && GQLModules?.map((Module, index) => {
                            return(
                                <>
                                    {Module.__typename === "GLSTRAPI_ComponentComponentsLocalExperience" && <LocalExperience key={Module} Module={Module} GQLPage={office} office_template={true} />}
                                </>
                            )
                        })
                    }

                    {office.latitude && office.longitude ?
                        <section className="map-section">
                            <div className="map-img">
                                <LocRatingMap data={mapItems} />
                            </div>
                        </section>
                        : ''
                    }

                    <Reviews /> 
                    <FeaturesOffice office_crm_id={office?.office_crm_id} tab_list={tab_list} />
                    <Journey />
                </>
                }
            </Layout>
            }
        </div>
        
    )
}

export default BranchDetails

export const query = graphql`
query offficeDetailQueryCustom($id: ID!) {
    glstrapi { 
        globalModule {
            branch_statistics {
                id
                icon_class
                heading
                content
            }
        }

        office(id: $id) {
            id
            slug
            publish
            latitude
            longitude
            office_crm_id
            name
            address
            mobile
            email
            show_statistics
            seo_meta {
                title
                keywords
                description
                id
                image {
                  alternativeText
                  url
                }
            }
            statistics_component {
                heading
                icon_class
                content
                id
            }
            opening_hours{
                id
                name
                value
            }
            teams(sort: "sort:asc") {
                name
                mobile_no
                id
                category
                email
                image {
                  alternativeText
                  url
                }
                imagetransforms
                designation
                slug
                title
                publish
                content
            }
            video_section{
                title
                content
                bg_color
                embed_video_url
                image{
                  url
                  alternativeText
                }
                Button_CTA{
                    theme
                    target
                    label
                    custom_link
                    id
                    menu_item {
                      slug
                      parent {
                        slug
                        parent {
                          slug
                          parent {
                            slug
                          }
                        }
                      }
                    }
                }
            }
            areas{
                id
                area_name
            }
            image{
                url
                alternativeText
            }
            imagetransforms

            Modules {
                __typename
                
                ... on GLSTRAPI_ComponentComponentsLocalExperience {
                    id
                    title
                    content
                    background_color: bg_color
                    button_cta {
                        theme
                        target
                        custom_link
                        id
                        label
                        menu_item {
                            slug
                            parent {
                                slug
                                parent {
                                    slug
                                    parent {
                                        slug
                                    }
                                }
                            }
                        }
                    }
                    image {
                        url
                        alternativeText
                    }
                    image_align
                }
            }
        } 
    }
}`